import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getExpenses } from "../../api";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { CalendarToday, DateRange, ViewWeek } from "@mui/icons-material";
import SummaryCards from "./SummaryCards";
import { deepPurple, deepOrange } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { Select, MenuItem } from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import Slider from "@mui/material/Slider";
import { set } from "date-fns";

function AnalyticsDashboard() {
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [option, setOption] = useState("daily");
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [Months, setMonths] = useState([]);
  const [Years, setYears] = useState([]);

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState([1, 31]);
  console.log(day);

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const fetchData = async () => {
    const data = await getExpenses();
    setTransactions(data);
    // Step 1: Get the unique months from the transactions
    const MonthsGroup = data.map((transaction) => {
      const date = new Date(transaction.created_at);
      const month = date.toLocaleString("default", { month: "short" });
      return month;
    });

    const YearsGroup = data.map((transaction) => {
      const date = new Date(transaction.created_at);
      const year = date.toLocaleString("default", { year: "numeric" });
      return year;
    });
    setYears(YearsGroup.filter((v, i, a) => a.indexOf(v) === i));
    setMonths(MonthsGroup.filter((v, i, a) => a.indexOf(v) === i));
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Filter transactions based on selected date range
  const filteredTransactions = transactions.filter((transaction) => {
    const transactionDate = new Date(transaction.created_at);
    return (
      (!startDate || transactionDate >= startDate) &&
      (!endDate || transactionDate <= endDate)
    );
  });

  // Calculate totals for incomes, expenses, and profit or loss
  const calculateData = () => {
    const totals = {};

    transactions
      .filter((transaction) => {
        const date = new Date(transaction.created_at);
        return (
          (!startDate || date >= startDate) && (!endDate || date <= endDate)
        );
      })
      .forEach((transaction) => {
        // Get the month or day of the transaction
        const date = new Date(transaction.created_at);

        let key;
        if (option === "monthly") {
          key = date.toLocaleString("default", { month: "short" });
        } else if (option === "daily") {
          key = date.toLocaleString("default", { day: "numeric" });
        } else if (option === "weekly") {
          key = date.toLocaleString("default", { weekday: "short" });
        }

        if (!totals[key]) {
          totals[key] = { income: 0, expenses: 0 };
        }
        if (transaction.is_income) {
          totals[key].income += transaction.equivalent_usd * 1;
        } else {
          totals[key].expenses += transaction.equivalent_usd * 1;
        }
      });

    const newData = Object.keys(totals).map((key) => ({
      name: key,
      income: totals[key].income,
      expenses: totals[key].expenses,
    }));

    // Step 3: Call this function whenever the option changes
    setData(newData);
  };

  // Recalculate the data whenever the option changes
  useEffect(() => {
    calculateData();
  }, [option, startDate, endDate, transactions]);

  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Analytics Dashboard
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={setEndDate}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <ResponsiveContainer width="100%" height={300}>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            startIcon={<CalendarToday />}
            onClick={() => setOption("daily")}
          >
            Daily
          </Button>
          <Button startIcon={<ViewWeek />} onClick={() => setOption("weekly")}>
            Weekly
          </Button>
          <Button
            startIcon={<DateRange />}
            onClick={() => setOption("monthly")}
          >
            Monthly
          </Button>
        </ButtonGroup>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="income"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="expenses" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          padding: 2,
          borderRadius: 1,
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Slider
          value={day}
          onChange={(event, newValue) => setDay(newValue)}
          getAriaValueText={(value) => `Day ${value}`}
          valueLabelFormat={(value) => `Day ${value}`}
          aria-labelledby="range-slider"
          valueLabelDisplay="auto"
          step={1}
          marks={[
            {
              value: 1,
              label: "Start",
            },
            {
              value: 31,
              label: "End",
            },
          ]}
          min={1}
          max={31}
          sx={{
            color: "secondary.main",
            "& .MuiSlider-thumb": {
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.16)",
              },
              "&.Mui-active": {
                boxShadow: "0px 0px 0px 14px rgba(0, 0, 0, 0.16)",
              },
            },
            width: "300px",
          }}
        />
        <FormControl
          variant="filled"
          sx={{
            marginBottom: 2,
            minWidth: 120,
            maxWidth: 200, // Limit the maximum width
            width: "auto", // Adjust the width based on content
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            borderRadius: 4,
            overflow: "hidden", // Hide the dropdown menu when it's open
          }}
        >
          <InputLabel id="month-select-label" sx={{ color: "white" }}>
            Month
          </InputLabel>
          <Select
            labelId="month-select-label"
            value={month}
            onChange={handleMonthChange}
            label="Month"
            sx={{
              color: "white",
              "& .MuiSelect-icon": {
                color: "white",
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "rgba(50, 50, 50, 0.9)", // Darker background color
                  color: "white",
                  borderRadius: 4,
                },
              },
            }}
          >
            {Months.map((month, index) => (
              <MenuItem
                key={index}
                value={month}
                sx={{
                  color: "white", // Change text color to white
                  padding: "10px 20px", // Add some padding
                }}
              >
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          variant="filled"
          sx={{
            marginBottom: 2,
            minWidth: 120,
            maxWidth: 200, // Limit the maximum width
            width: "auto", // Adjust the width based on content
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            borderRadius: 4,
            overflow: "hidden", // Hide the dropdown menu when it's open
          }}
        >
          <InputLabel id="year-select-label" sx={{ color: "white" }}>
            Year
          </InputLabel>
          <Select
            labelId="year-select-label"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            label="Year"
            sx={{
              color: "white",
              "& .MuiSelect-icon": {
                color: "white",
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "rgba(50, 50, 50, 0.9)", // Darker background color
                  color: "white",
                  borderRadius: 4,
                },
              },
            }}
          >
            {Years.map((year, index) => (
              <MenuItem
                key={index}
                value={year}
                sx={{
                  color: "white", // Change text color to white
                  padding: "10px 20px", // Add some padding
                }}
              >
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* slider for days in the month */}
      </Box>
      <SummaryCards
        transactions={transactions.filter((transaction) => {
          const date = new Date(transaction.created_at);
          return (
            // use the selected year and month and day to filter the transactions
            (!year ||
              date.toLocaleString("default", { year: "numeric" }) === year) &&
            (!month ||
              date.toLocaleString("default", { month: "short" }) === month) &&
            (!day || (day[0] <= date.getDate() && date.getDate() <= day[1]))
          );
        })}
      />
    </Box>
  );
}

export default AnalyticsDashboard;
