import axios from "axios";

// const baseUrl = 'https://patient.khalid.top/api'
const baseUrl = "https://finance.fast70app.com/api";
// const baseUrl = "https://server.fast70app.com/api";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "ngrok-skip-browser-warning": "69420",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

// get settings ,
export const createExpense = async (expense) => {
  const response = await instance.post(`${baseUrl}/expenses`, expense);
  return response.data;
};

export const getExpenses = async () => {
  const response = await instance.get(`${baseUrl}/expenses`);
  return response.data;
};

export const deleteExpense = async (id) => {
  const response = await instance.delete(`${baseUrl}/expenses/${id}`);
  return response.data;
};

export const updateExpense = async (id, updatedExpense) => {
  const response = await instance.patch(
    `${baseUrl}/expenses/${id}`,
    updatedExpense
  );
  return response.data;
};

// Categories
export const createCategory = async (category) => {
  const response = await instance.post(`${baseUrl}/categories`, category);
  return response.data;
};

export const getCategories = async () => {
  const response = await instance.get(`${baseUrl}/categories`);
  return response.data;
};
//update category
export const updateCategory = async (id, updatedCategory) => {
  const response = await instance.put(
    `${baseUrl}/categories/${id}`,
    updatedCategory
  );
  return response.data;
};

// Payment Methods
export const createPaymentMethod = async (paymentMethod) => {
  const response = await instance.post(
    `${baseUrl}/payment-methods`,
    paymentMethod
  );
  return response.data;
};

export const getPaymentMethods = async () => {
  const response = await instance.get(`${baseUrl}/payment-methods`);
  return response.data;
};
export const updatePaymentMethod = async (id, updatedPaymentMethod) => {
  const response = await instance.put(
    `${baseUrl}/payment-methods/${id}`,
    updatedPaymentMethod
  );
  return response.data;
};

// Currencies
export const createCurrency = async (currency) => {
  const response = await instance.post(`${baseUrl}/currencies`, currency);
  return response.data;
};

export const getCurrencies = async () => {
  const response = await instance.get(`${baseUrl}/currencies`);
  return response.data;
};
export const updateCurrency = async (id, updatedCurrency) => {
  const response = await instance.put(`${baseUrl}/currencies/${id}`, updatedCurrency);
  return response.data;
};




// get analytics
export const getAnalytics = async () => {
  const response = await instance.get(`${baseUrl}/analytics`);
  return response.data;
};
// login
export const loginUser = async (user) => {
  const response = await instance.post(`${baseUrl}/login`, user, {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  });
  console.log(response.data);
  return response.data;
};
