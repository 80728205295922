import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Box,
  ButtonGroup,

} from "@mui/material";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  ListSubheader,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ListItemIcon, ListItemText } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {getCategories , getCurrencies , createCategory , createCurrency , createPaymentMethod , createExpense , getPaymentMethods } from "../../api"
//toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { lightBlue, green , deepPurple , deepOrange } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
 import MoneyOffIcon from "@mui/icons-material/MoneyOff";
 import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

function ExpenseForm() {
  const [currentView, setCurrentView] = useState("expense");
  const [inputState, setInputState] = useState({
    title: "",
    amount: "",
    paymentMethod: "",
    currency: "",
    category: "",
    equivalentUSD: "",
  });
   const theme = useTheme();
   const colors = tokens(theme.palette.mode);

  // Example fetched data
  const [paymentMethods, setPaymentMethods] = useState([
    { id: 1, name: "Cash" },
    { id: 2, name: "Credit Card" },
    { id: 3, name: "Debit Card" },
    { id: 4, name: "Bank Transfer" },
    { id: 5, name: "Paypal" },
  ]); // Populate with fetched data
  const [currencies, setCurrancies] = useState([
    { id: 1,  name: "USD" },
    { id: 2,  name: "EUR" },
    { id: 3,  name: "GBP" },
    { id: 4,  name: "JPY" },
    { id: 5,  name: "CNY" }
  ]); // Populate with fetched data
  const [categories , setCategories] = useState( [
    { id: 1, name: "Ahmed Salem", is_website: false },
    { id: 2, name: "Mohamed Ali", is_website: false },
    { id: 3, name: "Google", is_website: true },
    { id: 4, name: "Facebook", is_website: true },
    { id: 5, name: "Twitter", is_website: true },
   ]); // Populate with fetched data

  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputState({ ...inputState, [name]: value });
  };

  // Example handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      !inputState.title ||
      !inputState.amount ||
      !inputState.paymentMethod ||
      !inputState.currency ||
      !inputState.category ||
      !inputState.equivalentUSD
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    const expense = {
      title: inputState.title,
      amount: inputState.amount*1,
      paymentMethod: inputState.paymentMethod,
      currency: inputState.currency,
      category: inputState.category,
      equivalentUSD: inputState.equivalentUSD * 1,
      is_income: currentView === "income",
    };
    createExpense(expense);
    setInputState({
      title: "",
      amount: 0,
      paymentMethod: "",
      currency: "",
      category: "",
      equivalentUSD: "",
    });
    toast.success(`${currentView} added successfully`);
    //
    // Submit logic
  };
  const [open, setOpen] = useState(false);
  const [openCurrency, setOpenCurrency] = useState(false);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);

  const [newCurrency, setNewCurrency] = useState({ name: "" , amount_to_usd: 1});
  const [newItem, setNewItem] = useState({ name: "", is_website: false });
  const [newPaymentMethod, setNewPaymentMethod] = useState({ name: "" });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenCurrency = () => {
    setOpenCurrency(true);
  };
  const handleOpenPaymentMethod = () => {
    setOpenPaymentMethod(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCurrency = () => {
    setOpenCurrency(false);
  };
  const handleClosePaymentMethod = () => {
    setOpenPaymentMethod(false);
  };

  const handleNewItemChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };
  const handleNewCurrencyChange = (e) => {
    setNewCurrency({ ...newCurrency, [e.target.name]: e.target.value });
  };
  const handleNewPaymentMethodChange = (e) => {
    setNewPaymentMethod({ ...newPaymentMethod, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.checked });
  };
  const handleAddNewCurrency = () => {
    //add new currency to your list here
    // Then close the dialog
    createCurrency(newCurrency);
    setCurrancies([...currencies, { id: currencies.length + 1, name: newCurrency.name , amount_to_usd: newCurrency.amount_to_usd}]);
    setNewCurrency({ name: "" , amount_to_usd: 1});
    toast.success(`Currency added successfully ${newCurrency.name}`);
    handleCloseCurrency();
  };
  const handleAddNewPaymentMethod = async() => {
    //add new payment method to your list here
    // Then close the dialog
    try {
      await createPaymentMethod(newPaymentMethod);
      setPaymentMethods([...paymentMethods, { id: paymentMethods.length + 1, name: newPaymentMethod.name }]);
      toast.success("Payment Method added successfully ");

    } catch (error) {
      toast.error("Error adding Payment Method");
    }
    setNewPaymentMethod({ name: "" });
    handleClosePaymentMethod();
  };

  const handleAddNewItem = () => {
    //add new category to your list here
    // Then close the dialog
    if (newItem.is_website === false) {
      const id = categories.length + 1;
      setCategories([ ...categories , {id: id, name: newItem.name , is_website: false}]);
    } else {
      const id = categories.length + 1;
      setCategories([ ...categories ,  {id: id, name: newItem.name , is_website: true}]);
    }    
    createCategory(newItem);
    toast.success(`Category added successfully ${newItem.name}`);

    setNewItem({ name: "", is_website: false });
    handleClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      const categories = await getCategories();
      setCategories(categories);
      const currencies = await getCurrencies();
      setCurrancies(currencies);
      const paymentMethods = await getPaymentMethods();
      setPaymentMethods(paymentMethods);
      console.log("categories", categories);
      console.log("currencies", currencies);
      console.log("paymentMethods", paymentMethods);
    };
    fetchData();
  }
    , []);
  
  const handleCurrencyChange = (event) => {
    const value = event.target.value;
    if (value === "dontDefaultIt123") {
      return;
    }
    
    const currency = currencies.find((currency) => currency.name === value);
    setInputState({ ...inputState, equivalentUSD: currency.amount_to_usd , currency: value });
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        minHeight: "100vh",
      }}
    >
      <ToastContainer />
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        fullWidth
      >
       
        <Button
          startIcon={<MoneyOffIcon />}
          sx={{
            backgroundColor: currentView === "expense" ? "#f44336" : "#e0e0e0", // red or light grey
            color: currentView === "expense" ? "#fff" : "#000", // white or black
            "&:hover": {
              backgroundColor: currentView === "expense" ? "#d32f2f" : "#bdbdbd", // darker red or darker grey
            },
          }}
          onClick={() => setCurrentView("expense")}
          fullWidth
        >
          expense
        </Button>
        <Button
          startIcon={<AttachMoneyIcon />}
          sx={{
            backgroundColor: currentView === "income" ? "#4caf50" : "#e0e0e0", // green or light grey
            color: currentView === "income" ? "#fff" : "#000", // white or black
            "&:hover": {
              backgroundColor: currentView === "income" ? "#388e3c" : "#bdbdbd", // darker green or darker grey
            },
          }}
          onClick={() => setCurrentView("income")}
          fullWidth
        >
          income
        </Button>
      </ButtonGroup>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          padding: 2,
          borderRadius: 1,
          boxShadow: 1, // shadow around the form
          width: "80%", // form width
          mt: 4, // margin top
          backgroundColor: colors.primary[400], // primary color
        }}
      >
        <Typography variant="h5"  gutterBottom>
          Add New {currentView}
        </Typography>
        <Paper sx={{ padding: 2, width: "90%", mb: 2 }}>
          <TextField
            name="title"
            label="Title"
            value={inputState.title}
            onChange={handleInputChange}
            fullWidth
            sx={{ width: "90%" }}
          />
          <TextField
            name="amount"
            label="Amount"
            type="number"
            value={inputState.amount}
            onChange={handleInputChange}
            fullWidth
            sx={{ width: "90%" }}
          />
        </Paper>

        <Paper sx={{ padding: 2, width: "90%", mb: 2, gap: "20px" }}>
          <Autocomplete
            value={inputState.currency}
            onChange={(event, newValue) => {
              if (newValue === "dontDefaultIt123") {
                handleOpenCurrency();
              } else {
                handleCurrencyChange({ target: { value: newValue } });
              }
            }}
            options={[
              ...currencies.map((option) => option.name),
              "dontDefaultIt123",
            ]}
            freeSolo={false} // disallow free solo input
            renderInput={(params) => <TextField {...params} label="Currency" />}
            renderOption={(props, option) => {
              if (option === "dontDefaultIt123") {
                return (
                  <li {...props}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add New" />
                  </li>
                );
              } else {
                return <li {...props}>{option}</li>;
              }
            }}
          />
          <TextField
            name="equivalentUSD"
            label="Equivalent USD"
            type="number"
            value={inputState.equivalentUSD}
            onChange={handleInputChange}
            disabled={
              inputState.currency === "USD" || inputState.currency === ""
            }
            sx={{ width: "90%" }}
          />
        </Paper>

        <Paper sx={{ padding: 2, width: "90%", mb: 2 }}>
          <Autocomplete
            value={inputState.paymentMethod}
            sx={{ width: "90%" }}
            onChange={(event, newValue) => {
              if (newValue === "Add New") {
                handleOpenPaymentMethod();
              } else {
                setInputState({ ...inputState, paymentMethod: newValue });
              }
            }}
            options={[
              ...paymentMethods.map((option) => option.name),
              "Add New",
            ]}
            freeSolo={false} // disallow free solo input
            renderInput={(params) => (
              <TextField {...params} label="Payment Method" />
            )}
            renderOption={(props, option, { selected }) => {
              if (option === "Add New") {
                return (
                  <li
                    style={{ backgroundColor: green[500], color: "white" }}
                    {...props}
                  >
                    <ListItemIcon sx={{ color: "white" }}>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add New" sx={{ color: "white" }} />
                  </li>
                );
              }
              return <li {...props}>{option}</li>;
            }}
          />

          <Autocomplete
            value={inputState.category}
            onChange={(event, newValue) => {
              if (newValue === "Add New") {
                handleOpen();
              } else {

                // remove the emoji from the category name
                if (newValue.includes("👨‍💼 ")) {
                  newValue = newValue.replace("👨‍💼 ", "");
                } else if (newValue.includes("🌐 ")) {
                  newValue = newValue.replace("🌐 ", "");
                }
                setInputState({ ...inputState, category: newValue });
              }
            }}
            options={[
              ...categories
                .filter((cat) => cat.is_website === false)
                .map((category) => "👨‍💼 " + category.name),
              ...categories
                .filter((cat) => cat.is_website === true)
                .map((category) => "🌐 " + category.name),
              "Add New",
            ]}
            renderInput={(params) => <TextField {...params} label="Category" />}
          />
        </Paper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            mt: 2,
            backgroundColor: currentView === "expense" ? deepOrange[500] : green[500], // orange or green
            "&:hover": {  backgroundColor: currentView === "expense" ? deepOrange[700] : green[700]},
          }} // green button
        >
          Submit
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              maxHeight: 435,
              backgroundColor: deepPurple[50], // light purple
            },
          }}
        >
          <DialogTitle sx={{ color: deepPurple[500] }}>
            Add New Category
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={newItem.name}
              onChange={handleNewItemChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="is_website"
                  checked={newItem.is_website}
                  onChange={handleCheckboxChange}
                />
              }
              label="Website ?"
            />
            <Button
              onClick={handleAddNewItem}
              sx={{
                backgroundColor: green[500],
                color: "#fff", // white
                "&:hover": { backgroundColor: green[700] }, // darker green
              }}
            >
              Add
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openCurrency}
          onClose={handleCloseCurrency}
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              maxHeight: 435,
              backgroundColor: lightBlue[50], // light blue
            },
          }}
        >
          <DialogTitle sx={{ color: lightBlue[500] }}>
            Add New Currency
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={newCurrency.name}
              onChange={handleNewCurrencyChange}
            />
            <TextField
              margin="dense"
              name="amount_to_usd"
              label="Amount to USD"
              type="number"
              fullWidth
              value={newCurrency.amount_to_usd}
              onChange={handleNewCurrencyChange}
            />
            <Button
              onClick={handleAddNewCurrency}
              sx={{
                backgroundColor: green[500],
                color: "#fff", // white
                "&:hover": { backgroundColor: green[700] }, // darker green
              }}
            >
              Add
            </Button>{" "}
          </DialogContent>
        </Dialog>
        <Dialog open={openPaymentMethod} onClose={handleClosePaymentMethod}>
          <DialogTitle>Add New Payment Method</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={newPaymentMethod.name}
              onChange={handleNewPaymentMethodChange}
            />
            <Button onClick={handleAddNewPaymentMethod}>Add</Button>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default ExpenseForm;
