import React from "react";
import { Box, Card, CardContent, Typography, Tooltip } from "@mui/material";
import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import StoreIcon from "@mui/icons-material/Store";
import MoneyIcon from "@mui/icons-material/Money";
import WorkIcon from "@mui/icons-material/Work";
import { Avatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";


// Assuming data has been aggregated in the calculateData function and includes currency details
function SummaryCards({ transactions }) {
   const theme = useTheme();
   const colors = tokens(theme.palette.mode);
  const totalIncome = transactions
    .filter((transaction) => transaction.is_income)
    .reduce((acc, transaction) => acc + transaction.equivalent_usd*1, 0);
  const totalExpenses = transactions
    .filter((transaction) => !transaction.is_income)
    .reduce((acc, transaction) => acc + transaction.equivalent_usd * 1, 0);
  const totalProfit = totalIncome - totalExpenses;
    const totals = {};
   transactions.forEach((transaction) => {
     const currency = transaction.currency.name;
     const amount = parseFloat(transaction.amount);


     if (!totals[currency]) {
       totals[currency] = { income: 0, expenses: 0 };
     }

     if (transaction.is_income) {
       totals[currency].income += amount;
     } else {
       totals[currency].expenses += amount;
     }

     totals[currency].profit =
       totals[currency].income - totals[currency].expenses;
   });
  const [openIncome, setOpenIncome] = React.useState(false);
  const [openExpenses, setOpenExpenses] = React.useState(false);
  const [openProfit, setOpenProfit] = React.useState(false);
  const [openMostProfitableWebsite, setOpenMostProfitableWebsite] = React.useState(false);
  const [openMostExpensableWebsite, setOpenMostExpensableWebsite] = React.useState(false);
  const [openHighestPaidEmployee, setOpenHighestPaidEmployee] = React.useState(false);

  const handleClickOpen = (type) => {
    if (type === "income") {
      setOpenIncome(true);
    } else if (type === "expenses") {
      setOpenExpenses(true);
    } else if (type === "profit") {
      setOpenProfit(true);
    } else if (type === "mostProfitableWebsite") {
      setOpenMostProfitableWebsite(true);
    } else if (type === "mostExpensableWebsite") {
      setOpenMostExpensableWebsite(true);
    }
    else if (type === "highestPaidEmployee") {
      setOpenHighestPaidEmployee(true);
    }

  }
  
  const handleClose = () => {
    setOpenIncome(false);
    setOpenExpenses(false);
    setOpenProfit(false); 
    setOpenMostProfitableWebsite(false);
    setOpenMostExpensableWebsite(false);
    setOpenHighestPaidEmployee(false);
  }
let websites = {};
transactions.forEach((transaction) => {
  if (transaction.category.is_website) {
    const website = transaction.category.name;
    const amount = parseFloat(transaction.equivalent_usd);

    if (!websites[website]) {
      websites[website] = { income: 0, expenses: 0 };
    }

    if (transaction.is_income) {
      websites[website].income += amount;
    } else {
      websites[website].expenses += amount;
    }

    websites[website].profit =
      websites[website].income - websites[website].expenses;
  }
});



// Calculate the highest paid employee
let employees = {};
transactions.forEach((transaction) => {
  if (!transaction.category.is_website) {
    const employee = transaction.category.name;
    const amount = parseFloat(transaction.equivalent_usd);

    if (!employees[employee]) {
      employees[employee] = 0;
    }

    employees[employee] += amount;
  }
});

let mostProfitableWebsite = Object.keys(websites).reduce(
  (a, b) =>
    websites[a] && websites[b] && websites[a].profit > websites[b].profit
      ? a
      : b,
  ""
);

let mostExpensableWebsite = Object.keys(websites).reduce(
  (a, b) =>
    websites[a] && websites[b] && websites[a].expenses > websites[b].expenses
      ? a
      : b,
  ""
);

let highestPaidEmployee = Object.keys(employees).reduce(
  (a, b) =>
    employees[a] && employees[b] && employees[a] > employees[b] ? a : b,
  ""
);


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
        flexWrap: "wrap",

        "@media (max-width: 600px)": {
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <Card
        sx={{ minWidth: 275, backgroundColor: colors.greenAccent[800], margin: "10px" }}
        onClick={() => handleClickOpen("income")}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14, color: "green" }} gutterBottom>
            <AttachMoneyIcon /> Total Income
          </Typography>
          <Typography variant="h2" component="div">
            ${totalIncome.toFixed(2)}
          </Typography>
        </CardContent>
      </Card>

      <Card
        sx={{ minWidth: 275, backgroundColor: colors.redAccent[800], margin: "10px" }}
        onClick={() => handleClickOpen("expenses")}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14, color: "red" }} gutterBottom>
            <MoneyOffIcon /> Total Expenses
          </Typography>
          <Typography variant="h2" component="div">
            ${totalExpenses.toFixed(2)}
          </Typography>
        </CardContent>
      </Card>

      <Card
        sx={{ minWidth: 275, backgroundColor: colors.blueAccent[900], margin: "10px" }}
        onClick={() => handleClickOpen("profit")}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14, color: "blue" }} gutterBottom>
            <TrendingUpIcon /> Total Profit
          </Typography>
          <Typography variant="h2" component="div">
            ${totalProfit.toFixed(2)}
          </Typography>
        </CardContent>
      </Card>
      <Card
        sx={{ minWidth: 275, backgroundColor: colors.greenAccent[900], margin: "10px" }}
        onClick={() => handleClickOpen("mostProfitableWebsite")}
      >
        <CardContent>
          <Avatar sx={{ bgcolor: "green", marginRight: "10px" }}>
            <StoreIcon />
          </Avatar>
          <Typography sx={{ fontSize: 14, color: "green" }} gutterBottom>
            Most Profitable Website
          </Typography>
          <Typography variant="h2" component="div">
            {mostProfitableWebsite}
          </Typography>
        </CardContent>
      </Card>

      <Card
        sx={{ minWidth: 275, backgroundColor: colors.redAccent[700], margin: "10px" }}
        onClick={() => handleClickOpen("mostExpensableWebsite")}
      >
        <CardContent>
          <Avatar sx={{ bgcolor: "red", marginRight: "10px" }}>
            <MoneyIcon />
          </Avatar>
          <Typography sx={{ fontSize: 14, color: "red" }} gutterBottom>
            Most Expensable Website
          </Typography>
          <Typography variant="h2" component="div">
            {mostExpensableWebsite}
          </Typography>
        </CardContent>
      </Card>

      <Card
        sx={{ minWidth: 275, backgroundColor: colors.blueAccent[600], margin: "10px" }} onClick={() => handleClickOpen("highestPaidEmployee")} >
        <CardContent>
          <Avatar sx={{ bgcolor: "blue", marginRight: "10px" }}>
            <WorkIcon />
          </Avatar>
          <Typography sx={{ fontSize: 14, color: "blue" }} gutterBottom>
            Highest Paid Employee
          </Typography>
          <Typography variant="h2" component="div">
            {highestPaidEmployee}
          </Typography>
        </CardContent>
      </Card>

      <Dialog onClose={handleClose} open={openIncome}>
        <DialogTitle sx={{ fontSize: "1.5rem" }}>
          <Typography sx={{ fontSize: "1.5rem" }} >
            
          Total Income
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.entries(totals).map(([currency, { income }]) => (
              <ListItem key={currency}>
                <ListItemText
                  primary={currency}
                  secondary={income.toFixed(2)}
                  primaryTypographyProps={{
                    sx: { fontSize: "1.2rem", fontWeight: "bold" },
                  }}
                  secondaryTypographyProps={{ sx: { fontSize: "1rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleClose} open={openExpenses}>
        <DialogTitle sx={{ fontSize: "1.5rem" }}>
          <Typography sx={{ fontSize: "1.5rem" }} >
          Total Expenses
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.entries(totals).map(([currency, { expenses }]) => (
              <ListItem key={currency}>
                <ListItemText
                  primary={currency}
                  secondary={expenses.toFixed(2)}
                  primaryTypographyProps={{
                    sx: { fontSize: "1.2rem", fontWeight: "bold" },
                  }}
                  secondaryTypographyProps={{ sx: { fontSize: "1rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleClose} open={openProfit}>
        <DialogTitle sx={{ fontSize: "1.5rem" }}>
          <Typography sx={{ fontSize: "1.5rem" }} >
            
          Total Profit
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.entries(totals).map(([currency, { profit }]) => (
              <ListItem key={currency}>
                <ListItemText
                  primary={currency}
                  secondary={profit.toFixed(2)}
                  primaryTypographyProps={{
                    sx: { fontSize: "1.2rem", fontWeight: "bold" },
                  }}
                  secondaryTypographyProps={{ sx: { fontSize: "1rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
                  
      <Dialog onClose={handleClose} open={openMostProfitableWebsite}>
        <DialogTitle sx={{ fontSize: "1.5rem" }}>
          <Typography sx={{ fontSize: "1.5rem" }} >
            
          Most Profitable Website
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.entries(websites).map(([website, { profit }]) => (
              <ListItem key={website}>
                <ListItemText
                  primary={website}
                  secondary={profit.toFixed(2)}
                  primaryTypographyProps={{
                    sx: { fontSize: "1.2rem", fontWeight: "bold" },
                  }}
                  secondaryTypographyProps={{ sx: { fontSize: "1rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
                  
      <Dialog onClose={handleClose} open={openMostExpensableWebsite}>
        <DialogTitle sx={{ fontSize: "1.5rem" }}>
          <Typography sx={{ fontSize: "1.5rem" }} >
            
          Most Expensable Website
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.entries(websites).map(([website, { expenses }]) => (
              <ListItem key={website}>
                <ListItemText
                  primary={website}
                  secondary={expenses.toFixed(2)}
                  primaryTypographyProps={{
                    sx: { fontSize: "1.2rem", fontWeight: "bold" },
                  }}
                  secondaryTypographyProps={{ sx: { fontSize: "1rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleClose} open={openHighestPaidEmployee}>
        <DialogTitle sx={{ fontSize: "1.5rem" }}>
          <Typography sx={{fontSize:"1.5rem"}} >Highest Paid Employee</Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.entries(employees).map(([employee, amount]) => (
              <ListItem key={employee}>
                <ListItemText
                  primary={employee}
                  secondary={amount.toFixed(2)}
                  primaryTypographyProps={{
                    sx: { fontSize: "1.2rem", fontWeight: "bold" },
                  }}
                  secondaryTypographyProps={{ sx: { fontSize: "1rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

    </Box>
  );
}

export default SummaryCards;
