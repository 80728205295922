import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import { getExpenses , deleteExpense , getPaymentMethods } from "../../api";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import Pagination from '@mui/material/Pagination';


function TransactionHistoryPage() {
   const theme = useTheme();
   const colors = tokens(theme.palette.mode);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getPaymentMethods().then((data) => {
      setPaymentMethods(data);
    });
  }, []);

  const fetchedTransactions = async () => {
    const data = await getExpenses();
    setTransactions(data);
    setFilteredTransactions(data);
  };
  useEffect(() => {
    // Simulate fetching data
    fetchedTransactions();
  }, []);
  const handleDelete = (id) => {
    deleteExpense(id).then(() => {
      fetchedTransactions();
      toast.success("Transaction deleted successfully");
    });
  };

  const handleSearch = () => {
    let result = transactions.filter(
      (transaction) =>
        transaction.category.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) &&
        (!startDate || new Date(transaction.created_at) >= startDate) &&
        (!endDate || new Date(transaction.created_at) <= endDate) &&
        (!selectedPaymentMethod ||  transaction.payment_method.name === selectedPaymentMethod.name)
    );
    setFilteredTransactions(result);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ToastContainer />
      <Box sx={{ p: 3, minWidth: "380px" }}>
        <Typography variant="h4" gutterBottom>
          Transaction History
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              label="Search by Category"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              sx={{ width: 150 }}
            options={paymentMethods}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSelectedPaymentMethod(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Payment Method"
                variant="outlined"
              />
            )}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} />}

            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
              
            />
          </Grid>
          <Grid item>
            <Button variant="contained"
            style={{backgroundColor: colors.greenAccent[500]}}
              onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
        {filteredTransactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice((page - 1) * 20, page * 20)
          .map((transaction, index) => (
            <Card
              key={index}
              sx={{
                mt: 2,
                backgroundColor: transaction.is_income ? colors.greenAccent[100] : colors.redAccent[100],
                color: "#000", // black text
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ color: "#3f51b5" }}
                    >
                      {" "}
                      {/* Blue color for the title */}
                      {transaction.title}
                    </Typography>
                    <Typography variant="body1" style={{ color: "#388e3c" }}>
                      {" "}
                      {/* Green color for the amount */}
                      Amount: {transaction.amount} {transaction.currency.name}{" "}
                      (USD {transaction.equivalent_usd})
                    </Typography>
                    <Typography variant="body1" style={{ color: "#f57c00" }}>
                      {" "}
                      {/* Orange color for the date */}
                      Date:{" "}
                      {new Date(transaction.created_at).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1" style={{ color: "#5e35b1" }}>
                      {" "}
                      {/* Purple color for the payment method */}
                      Payment Method: {transaction.payment_method.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: "10px",
                        backgroundColor: "#e8eaf6",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold", color: "#d32f2f" }}
                      >
                        {" "}
                        {/* Red color for the category */}
                        {transaction.category.name}{" "}
                        {transaction.category.is_website ? "🌐" : "👤"}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(transaction.id)}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
      <Pagination count={Math.ceil(transactions.length / 20)} page={page} onChange={(event, value) => setPage(value)} 
        style={{ display: "flex", justifyContent: "center", marginTop: "20px"  }} />
      </Box>
      
    </LocalizationProvider>
  );
}

export default TransactionHistoryPage;
