import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Add from "./scenes/add/index.jsx";
import Categories from "./scenes/Categories/index.jsx";
import Login from "./scenes/Login/index.jsx";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import  {AuthContext}  from "./context/auth";
import { useContext , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Currencies from "./scenes/Currencies/index.jsx";
import History from "./scenes/History/index.jsx";
import Home from "./scenes/Home";
import Topbar from "./scenes/global/Topbar.jsx";


// بالظابط

function App() {
  const { isAuthenticated } = useContext(AuthContext);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
 
  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} isSidebar={isSidebar} />
            <Routes>
              <Route
                path="/add"
                element={
                  <>
                    <Add />
                  </>
                }
              />
              <Route
                path="/categories"
                element={
                  <>
                    <Categories />
                  </>
                }
              />
              <Route
                path="/Currencies"
                element={
                  <>
                    <Currencies />
                  </>
                }
              />
              <Route
                path="/History"
                element={
                  <>
                    <History />
                  </>
                }
              />
              <Route
                path="/"
                  exact
                element={
                  <>
                    <Home />
                  </>
                }
              />
              {/* <Route
                  path="/users"
                  element={
                    <>
                      <Problem />
                    </>
                  }
                />
                <Route
                  path="/PaymentMethods"
                  element={
                    <>
                      <PaymentMethods />
                    </>
                  }
                />
                
                
                <Route path="/geography" element={<Geography />} /> */}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
