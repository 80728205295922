import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemAvatar,
  Avatar,
  ButtonGroup,


} from "@mui/material";
import { deepPurple } from "@mui/material/colors";

// Icons

import { Box } from "@mui/material";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import WebIcon from "@mui/icons-material/Web";


import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { getCategories, createCategory , updateCategory } from "../../api"; // Adjust with your actual API calls
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getExpenses } from "../../api";

function EmployeeCategoriesPage() {
  const [currentView, setCurrentView] = useState("employees");
  const [employees, setEmployees] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    fetchEmployees();

  }, []);

  const fetchEmployees = async () => {
    const data = await getCategories(); // Your API call here
    const Expenses = await getExpenses();
    setExpenses(Expenses);
    setEmployees(data);
  };

  const handleEditClick = (employee) => {
    setCurrentEmployee(employee);
    setEditOpen(true);
  };

  const handleEditSubmit = async () => {
    // await updateEmployee(currentEmployee); // Your API call here
    await updateCategory(currentEmployee.id, currentEmployee); // Your API call here
    toast.success("Employee updated successfully");
    setEditOpen(false);
    fetchEmployees(); // Refresh the list
  };

  const handleAddNew = async (name) => {
    await createCategory({ name , is_website: currentView === "employees" ? false : true  }); // Your API call here
    setAddOpen(false);
    fetchEmployees(); // Refresh the list
  };
  const [search, setSearch] = useState("");
const handleSearchChange = (event) => {
  setSearch(event.target.value);
};
  // check how much the employee has taken from expenses and the category name
  const handleEmployeeExpenses = (employee) => {
    let total = 0;
    expenses.map((expense) => {
      if (expense.category.name === employee.name) {

        total += (expense.equivalent_usd * 1 );
      }
    });
    return total.toFixed(2);
  }
  return (
    <Box>
      <ToastContainer />
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        fullWidth
      >
        <Button
          startIcon={<GroupWorkIcon />}
          sx={{
            backgroundColor:
              currentView === "employees" ? "#2196f3" : "#e0e0e0", // light blue or light grey
            color: currentView === "employees" ? "#fff" : "#000", // white or black
            "&:hover": {
              backgroundColor:
                currentView === "employees" ? "#1976d2" : "#bdbdbd", // darker blue or darker grey
            },
          }}
          onClick={() => setCurrentView("employees")}
          fullWidth
        >
          Employees
        </Button>
        <Button
          startIcon={<WebIcon />}
          sx={{
            backgroundColor: currentView === "websites" ? "#2196f3" : "#e0e0e0", // light blue or light grey
            color: currentView === "websites" ? "#fff" : "#000", // white or black
            "&:hover": {
              backgroundColor:
                currentView === "websites" ? "#1976d2" : "#bdbdbd", // darker blue or darker grey
            },
          }}
          onClick={() => setCurrentView("websites")}
          fullWidth
        >
          Websites
        </Button>
      </ButtonGroup>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        fullWidth
      >

        <Button
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#3f51b5", // indigo
            color: "#fff", // white
            "&:hover": {
              backgroundColor: "#303f9f", // darker indigo
            },
          }}
          onClick={() => setAddOpen(true)}
          fullWidth
        >
          Add {currentView === "employees" ? "Employee" : "Website"}
        </Button>
      </ButtonGroup>
      <TextField
        label="Search"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        sx={{ width: "100%", marginBottom: 2 }}
      />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {employees
          .filter((em) => em.is_website === (currentView === "websites"))
          .filter((em) => em.name.toLowerCase().includes(search.toLowerCase()))
          .map((employee) => (
            <ListItem
              key={employee.id}
              secondaryAction={
                <EditIcon onClick={() => handleEditClick(employee)} />
              }
              sx={{
                "&:hover": { bgcolor: "action.hover" },
                cursor: "pointer",
                margin: "8px",
                borderRadius: "4px",
              }}
            >
             
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: deepPurple[500] }}>
                  {/* Use the first letter of the name as the avatar */}
                  {employee.name[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={employee.name} />

              
              <ListItemText primary={handleEmployeeExpenses(employee)} />
            </ListItem>
          ))}
      </List>

      {/* Edit Dialog */}
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>
          Edit {currentView === "employees" ? "Employee" : "Website"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentEmployee.name}
            onChange={(e) =>
              setCurrentEmployee({ ...currentEmployee, name: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Add Dialog */}
      <Dialog open={addOpen} onClose={() => setAddOpen(false)}>
        <DialogTitle>
          Add New {currentView === "employees" ? "Employee" : "Website"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => setCurrentEmployee({ name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddOpen(false)}>Cancel</Button>
          <Button onClick={() => handleAddNew(currentEmployee.name)}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EmployeeCategoriesPage;
