import { Box } from "@mui/material";
import Header from "../../components/Header";
import { loginUser } from "../../api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import { AuthContext } from "../../context/auth"
import { useContext } from "react";


const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(formData);
      const response = await loginUser(formData);
      if (response.token) {
        console.log(response.token);
        login(response.token);
        localStorage.setItem("user", JSON.stringify(response.user)); // storing user data in localstorage
        navigate("/");
        setFormData({
          email: "",
          password: "",
        });
      }
    } catch (err) {
      console.log(err);}
  };
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Typography variant="h4" color="primary">
          Admin login
        </Typography>
        <Typography variant="h5" sx={{ mt: 4 }}>
          Sign in to your account
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 4, width: "100%" }}
        >
          <TextField
            id="email"
            name="email"
            type="email"
            label="Email address"
            autoComplete="email"
            required
            fullWidth
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <TextField
            id="password"
            name="password"
            type="password"
            label="Password"
            autoComplete="current-password"
            required
            fullWidth
            value={formData.password}
            onChange={handleChange}
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign in
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
