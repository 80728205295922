import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import { deepPurple , green , red } from "@mui/material/colors";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import WebIcon from "@mui/icons-material/Web";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  getPaymentMethods,
  createPaymentMethod,
  updatePaymentMethod,
  getCurrencies,
  createCurrency,
  updateCurrency,
} from "../../api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
        import PaymentIcon from "@mui/icons-material/Payment";
        import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

function PaymentMethodsAndCurrenciesPage() {
  const [currentView, setCurrentView] = useState("paymentMethods"); // "paymentMethods" or "currencies"
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});


  // Fetch data on mount
  useEffect(() => {
    fetchPaymentMethods();
    fetchCurrencies();
  }, []);

  const fetchPaymentMethods = async () => {
    const methods = await getPaymentMethods(); // Implement this function
    setPaymentMethods(methods);
  };

  const fetchCurrencies = async () => {
    const curr = await getCurrencies(); // Implement this function
    setCurrencies(curr);
  };

  const handleOpen = () => {
    setAddOpen(true);
  };

  const handleEditClick = (item) => {
    setCurrentItem(item);
    setEditOpen(true);
  };

  const handleEditSubmit = async () => {
    if (currentView === "paymentMethods") {
      await updatePaymentMethod(currentItem.id, currentItem);
      fetchPaymentMethods();
      toast.success("Item updated successfully");
    } else {
      await updateCurrency(currentItem.id, currentItem);
      fetchCurrencies();
      toast.success("Item updated successfully");
    }
    setEditOpen(false);
  };

  const handleAddNew = async (name) => {
    if (currentView === "paymentMethods") {
      await createPaymentMethod({ name });
      fetchPaymentMethods();
    } else {
      await createCurrency({ name , amount_to_usd: currentItem.amount_to_usd || 1});
      fetchCurrencies();
    }
    setAddOpen(false);

  };


  return (
    <Box sx={{ p: 2 }}>
      <ToastContainer />
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        fullWidth
      >

        <Button
          startIcon={<PaymentIcon />}
          sx={{
            backgroundColor:
              currentView === "paymentMethods" ? "#2196f3" : "#e0e0e0", // light blue or light grey
            color: currentView === "paymentMethods" ? "#fff" : "#000", // white or black
            "&:hover": {
              backgroundColor:
                currentView === "paymentMethods" ? "#1976d2" : "#bdbdbd", // darker blue or darker grey
            },
          }}
          onClick={() => setCurrentView("paymentMethods")}
          fullWidth
        >
          Payment Methods
        </Button>
        <Button
          startIcon={<AttachMoneyIcon />}
          sx={{
            backgroundColor:
              currentView === "currencies" ? "#2196f3" : "#e0e0e0", // light blue or light grey
            color: currentView === "currencies" ? "#fff" : "#000", // white or black
            "&:hover": {
              backgroundColor:
                currentView === "currencies" ? "#1976d2" : "#bdbdbd", // darker blue or darker grey
            },
          }}
          onClick={() => setCurrentView("currencies")}
          fullWidth
        >
          Currencies
        </Button>
      </ButtonGroup>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        fullWidth
      >
        <Button
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#3f51b5", // indigo
            color: "#fff", // white
            "&:hover": {
              backgroundColor: "#303f9f", // darker indigo
            },
          }}
          onClick={handleOpen}
          fullWidth
        >
          Add New
        </Button>
      </ButtonGroup>
      <List>
        {(currentView === "paymentMethods" ? paymentMethods : currencies).map(
          (item) => (
            <ListItem
              key={item.id}
              secondaryAction={
                <IconButton onClick={() => handleEditClick(item)}>
                  <EditIcon />
                </IconButton>
              }
              sx={{
                "&:hover": { bgcolor: "action.hover" },
                cursor: "pointer",
                margin: "8px",
                borderRadius: "4px",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor:
                      currentView === "paymentMethods" ? red[500] : green[500],
                  }}
                >
                  {item.name[0].toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={
                  currentView === "currencies"
                    ? `USD Conversion Rate: ${item.amount_to_usd}`
                    : null
                }
              />
            </ListItem>
          )
        )}
      </List>
      {/* Dialogs for adding or editing items */}
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>
          Edit{" "}
          {currentView === "paymentMethods" ? "Payment Method" : "Currency"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentItem.name}
            onChange={(e) =>
              setCurrentItem({ ...currentItem, name: e.target.value })
            }
          />
          {currentView === "currencies" && (
            <TextField
              margin="dense"
              label="USD Conversion Rate"
              type="number"
              fullWidth
              variant="outlined"
              value={currentItem.amount_to_usd}
              onChange={(e) =>
                setCurrentItem({
                  ...currentItem,
                  amount_to_usd: e.target.value,
                })
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>{" "}
      <Dialog open={addOpen} onClose={() => setAddOpen(false)}>
        <DialogTitle>
          Add New{" "}
          {currentView === "paymentMethods" ? "Payment Method" : "Currency"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setCurrentItem({ ...currentItem, name: e.target.value })
            }
          />
          {currentView === "currencies" && (
            <TextField
              margin="dense"
              label="USD Conversion Rate"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) =>
                setCurrentItem({
                  ...currentItem,
                  amount_to_usd: e.target.value,
                })
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddOpen(false)}>Cancel</Button>
          <Button onClick={() => handleAddNew(currentItem.name)}>Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default PaymentMethodsAndCurrenciesPage;
